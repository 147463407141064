// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-corporate-site-views-news-index-tsx": () => import("./../../../src/corporate-site/views/news/index.tsx" /* webpackChunkName: "component---src-corporate-site-views-news-index-tsx" */),
  "component---src-corporate-site-views-notice-index-tsx": () => import("./../../../src/corporate-site/views/notice/index.tsx" /* webpackChunkName: "component---src-corporate-site-views-notice-index-tsx" */),
  "component---src-corporate-site-views-product-index-tsx": () => import("./../../../src/corporate-site/views/product/index.tsx" /* webpackChunkName: "component---src-corporate-site-views-product-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-active-acc-next-tsx": () => import("./../../../src/pages/active-acc-next.tsx" /* webpackChunkName: "component---src-pages-active-acc-next-tsx" */),
  "component---src-pages-andrex-andrex-complete-tsx": () => import("./../../../src/pages/andrex/andrex-complete.tsx" /* webpackChunkName: "component---src-pages-andrex-andrex-complete-tsx" */),
  "component---src-pages-andrex-andrex-ended-tsx": () => import("./../../../src/pages/andrex/andrex-ended.tsx" /* webpackChunkName: "component---src-pages-andrex-andrex-ended-tsx" */),
  "component---src-pages-andrex-andrex-registration-tsx": () => import("./../../../src/pages/andrex/andrex-registration.tsx" /* webpackChunkName: "component---src-pages-andrex-andrex-registration-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-blog-hk-tsx": () => import("./../../../src/pages/blog/hk.tsx" /* webpackChunkName: "component---src-pages-blog-hk-tsx" */),
  "component---src-pages-blue-theme-tsx": () => import("./../../../src/pages/blue-theme.tsx" /* webpackChunkName: "component---src-pages-blue-theme-tsx" */),
  "component---src-pages-careers-hk-tsx": () => import("./../../../src/pages/careers/hk.tsx" /* webpackChunkName: "component---src-pages-careers-hk-tsx" */),
  "component---src-pages-cms-tsx": () => import("./../../../src/pages/cms.tsx" /* webpackChunkName: "component---src-pages-cms-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-coporate-site-tsx": () => import("./../../../src/pages/coporate-site.tsx" /* webpackChunkName: "component---src-pages-coporate-site-tsx" */),
  "component---src-pages-cs-active-acc-tsx": () => import("./../../../src/pages/cs/active-acc.tsx" /* webpackChunkName: "component---src-pages-cs-active-acc-tsx" */),
  "component---src-pages-cs-verify-tsx": () => import("./../../../src/pages/cs/verify.tsx" /* webpackChunkName: "component---src-pages-cs-verify-tsx" */),
  "component---src-pages-currency-rate-tsx": () => import("./../../../src/pages/currency-rate.tsx" /* webpackChunkName: "component---src-pages-currency-rate-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forget-pw-next-tsx": () => import("./../../../src/pages/forget-pw-next.tsx" /* webpackChunkName: "component---src-pages-forget-pw-next-tsx" */),
  "component---src-pages-iams-debug-tsx": () => import("./../../../src/pages/iams/debug.tsx" /* webpackChunkName: "component---src-pages-iams-debug-tsx" */),
  "component---src-pages-iams-demo-tsx": () => import("./../../../src/pages/iamsDemo.tsx" /* webpackChunkName: "component---src-pages-iams-demo-tsx" */),
  "component---src-pages-iams-index-tsx": () => import("./../../../src/pages/iams/index.tsx" /* webpackChunkName: "component---src-pages-iams-index-tsx" */),
  "component---src-pages-important-notice-tsx": () => import("./../../../src/pages/important-notice.tsx" /* webpackChunkName: "component---src-pages-important-notice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maintenance-html-tsx": () => import("./../../../src/pages/maintenance.html.tsx" /* webpackChunkName: "component---src-pages-maintenance-html-tsx" */),
  "component---src-pages-news-centre-tsx": () => import("./../../../src/pages/news-centre.tsx" /* webpackChunkName: "component---src-pages-news-centre-tsx" */),
  "component---src-pages-online-claim-tsx": () => import("./../../../src/pages/online-claim.tsx" /* webpackChunkName: "component---src-pages-online-claim-tsx" */),
  "component---src-pages-pdf-viewer-tsx": () => import("./../../../src/pages/pdf-viewer.tsx" /* webpackChunkName: "component---src-pages-pdf-viewer-tsx" */),
  "component---src-pages-privacy-addendum-for-mainland-china-tsx": () => import("./../../../src/pages/privacy-addendum-for-mainland-china.tsx" /* webpackChunkName: "component---src-pages-privacy-addendum-for-mainland-china-tsx" */),
  "component---src-pages-privacy-policy-statement-tsx": () => import("./../../../src/pages/privacy-policy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-statement-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-recommendations-tsx": () => import("./../../../src/pages/product-recommendations.tsx" /* webpackChunkName: "component---src-pages-product-recommendations-tsx" */),
  "component---src-pages-promotion-content-tsx": () => import("./../../../src/pages/promotion-content.tsx" /* webpackChunkName: "component---src-pages-promotion-content-tsx" */),
  "component---src-pages-promotions-tsx": () => import("./../../../src/pages/promotions.tsx" /* webpackChunkName: "component---src-pages-promotions-tsx" */),
  "component---src-pages-promotions-wechat-end-tsx": () => import("./../../../src/pages/promotions/wechat-end.tsx" /* webpackChunkName: "component---src-pages-promotions-wechat-end-tsx" */),
  "component---src-pages-promotions-wechat-registration-tsx": () => import("./../../../src/pages/promotions/wechat-registration.tsx" /* webpackChunkName: "component---src-pages-promotions-wechat-registration-tsx" */),
  "component---src-pages-promotions-wechat-result-tsx": () => import("./../../../src/pages/promotions/wechat-result.tsx" /* webpackChunkName: "component---src-pages-promotions-wechat-result-tsx" */),
  "component---src-pages-qnb-benefit-pdf-tsx": () => import("./../../../src/pages/qnb/benefit-pdf.tsx" /* webpackChunkName: "component---src-pages-qnb-benefit-pdf-tsx" */),
  "component---src-pages-qnb-super-progress-bar-tsx": () => import("./../../../src/pages/qnb/super-progress-bar.tsx" /* webpackChunkName: "component---src-pages-qnb-super-progress-bar-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-subscription-form-tsx": () => import("./../../../src/pages/subscription-form.tsx" /* webpackChunkName: "component---src-pages-subscription-form-tsx" */),
  "component---src-pages-sustainability-tsx": () => import("./../../../src/pages/sustainability.tsx" /* webpackChunkName: "component---src-pages-sustainability-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-test-404-tsx": () => import("./../../../src/pages/test404.tsx" /* webpackChunkName: "component---src-pages-test-404-tsx" */),
  "component---src-pages-test-animation-tsx": () => import("./../../../src/pages/test-animation.tsx" /* webpackChunkName: "component---src-pages-test-animation-tsx" */),
  "component---src-pages-watsons-pharmaeasy-barcode-tsx": () => import("./../../../src/pages/watsons/pharmaeasy-barcode.tsx" /* webpackChunkName: "component---src-pages-watsons-pharmaeasy-barcode-tsx" */),
  "component---src-pages-watsons-pharmaeasy-product-info-tsx": () => import("./../../../src/pages/watsons/pharmaeasy-product-info.tsx" /* webpackChunkName: "component---src-pages-watsons-pharmaeasy-product-info-tsx" */),
  "component---src-pages-watsons-pharmaeasy-registration-tsx": () => import("./../../../src/pages/watsons/pharmaeasy-registration.tsx" /* webpackChunkName: "component---src-pages-watsons-pharmaeasy-registration-tsx" */),
  "component---src-pages-wemedi-gba-dental-protector-faq-tsx": () => import("./../../../src/pages/wemedi-gba-dental-protector-faq.tsx" /* webpackChunkName: "component---src-pages-wemedi-gba-dental-protector-faq-tsx" */),
  "component---src-pages-wemedi-hk-dental-protector-faq-tsx": () => import("./../../../src/pages/wemedi-hk-dental-protector-faq.tsx" /* webpackChunkName: "component---src-pages-wemedi-hk-dental-protector-faq-tsx" */),
  "component---src-pages-wish-list-tsx": () => import("./../../../src/pages/wish-list.tsx" /* webpackChunkName: "component---src-pages-wish-list-tsx" */),
  "component---src-qnb-views-product-page-template-apply-tsx": () => import("./../../../src/qnb/views/product-page-template/apply.tsx" /* webpackChunkName: "component---src-qnb-views-product-page-template-apply-tsx" */),
  "component---src-qnb-views-product-page-template-index-tsx": () => import("./../../../src/qnb/views/product-page-template/index.tsx" /* webpackChunkName: "component---src-qnb-views-product-page-template-index-tsx" */),
  "component---src-qnb-views-product-page-template-thankyou-tsx": () => import("./../../../src/qnb/views/product-page-template/thankyou.tsx" /* webpackChunkName: "component---src-qnb-views-product-page-template-thankyou-tsx" */)
}

